@import 'variables.scss';

.download-overlay {
  background-color: $cta;
  border-radius: 4px;
  margin-top: $headerHeight;
  padding: 50px;
  position: relative;
  width: 410px;

  a {
    width: 100%;
  }
  &__floater {
    position: absolute;
    top: -60px;
  }
  &__close {
    cursor: pointer;
    opacity: 0.7;
    position: absolute;
    right: 10px;
    top: 10px;
    transition: opacity $shortTransition ease-in-out;

    &:hover {
      opacity: 1;
    }
  }
  &__title {
    color: $white;
    font-family: $demiBoldFont;
    font-size: 32px;
    line-height: 39px;
    margin: 80px 0 30px 0;
  }
  &__download {
    color: $white;
    display: block;
    font-family: $demiBoldFont;
    font-size: 24px;
    margin: 100px 0 30px 0;
    text-align: center;
  }
  &__blocker {
    align-items: center;
    background-color: rgba($white, 0.9);
    display: flex;
    height: 100vh;
    justify-content: center;
    left: 0;
    opacity: 0;
    pointer-events: none;
    position: fixed;
    top: 0;
    transition: opacity $shortTransition ease-in-out, transform $shortTransition ease-in-out;
    transform: translate3d(0, 25px, 0);
    width: 100vw;
    z-index: 400;

    &--show {
      opacity: 1;
      pointer-events: auto;
      transform: translate3d(0, 0, 0);

      .download-overlay__floater {
        animation: floater1 3s ease-in-out infinite;
      }
    }
  }

  @include mobile() {
    padding: 50px 30px 80px 30px;
    width: calc(100vw - 20px);

    &__floater {
      top: -80px;
    }
  }
}
