@import 'variables.scss';

$planePath: 'M-1.05777 313.846C100.74 266.827 231.838 136.914 543.682 244.481C763.915 320.447 999.249 193.596 1032 177.5';

.three-column-image {
  .full-width-inner {
    padding: 60px 0;
    position: relative;
  }
  &__title {
    font-family: $boldFont;
    font-size: 60px;
    line-height: 64px;
    margin: 0 0 5px 0;
  }
  &__text {
    font-family: $demiBoldFont;
    font-size: 28px;
    line-height: 1.4;
    margin: 0 0 80px 0;
  }
  &__item {
    opacity: 0;
    position: relative;
    margin-bottom: 60px;
    transition: opacity $mediumTransition ease-in-out;

    &--active {
      opacity: 1;
    }
    &__image {
      display: flex;
      height: 160px;
      justify-content: center;
      margin-bottom: 20px;

      img {
        display: block;
        max-height: 100%;
        max-width: 100%;
      }
    }
    &__title {
      font-family: $demiBoldFont;
      font-size: 24px;
      margin-bottom: 15px;
      text-align: center;
    }
    &__text {
      color: $greyDark;
      font-size: 20px;
      text-align: center;
    }
  }
  &__content {
    position: relative;
    z-index: 200;
  }
  &__plane-path {
    left: 160px;
    opacity: 0;
    position: absolute;
    top: 230px;
    z-index: 100;
  }
  &__plane {
    left: 170px;
    motion-path: path($planePath);
    offset-path: path($planePath);
    position: absolute;
    top: 220px;
    transform: rotate(38deg);
    width: 80px;
    z-index: 200;
  }
  &.slider-block--activated &__plane-path {
    opacity: 1;
  }

  @include mobile() {
    .full-width-inner {
      margin-bottom: 140px;
      padding: 60px $horizontalPaddingMobile 140px $horizontalPaddingMobile;
    }
    &__title {
      font-size: 35px;
      line-height: 36px;
      margin: 0 0 15px 0;
    }
    &__text {
      line-height: 1.2;
      margin: 0 0 60px 0;
    }
    &__item {
      &__number {
        text-align: center;
      }
      &__title {
        max-width: none;
        text-align: center;
      }
      &__logo {
        left: 50%;
        top: 25px;
      }
    }
    &__plane-path-fader,
    &__plane-path,
    &__plane {
      display: none;
    }
  }
}
