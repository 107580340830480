@import 'variables.scss';

.video-thumbnail {
  background-repeat: no-repeat;
  height: 100%;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.video-player-container {
  padding: 25px 50px 0 0;

  @include mobile() {
    padding: 0;
  }
}

.clip-thumbnail {
  clip-path: url('#image-mask');
  display: block;
  height: 100%;
  position: relative;
  width: 100%;
}

.video-thumbnail-play-button {
  width: 64px;
  height: 64px;
  filter: drop-shadow(0 4px 20px rgba(0, 0, 0, 0.03)) drop-shadow(0 0px 8px rgba(0, 0, 0, 0.08));
  transition-property: width height;
  transition-duration: 150ms;
  transition-timing-function: ease-in-out;
}

.video-thumbnail-play-button:hover {
  width: 70px;
  height: 70px;
}

.hero-with-text {
  overflow: hidden;
  padding: 80px 0;

  &__title {
    color: $black;
    font-family: $boldFont;
    font-size: 70px;
    letter-spacing: $letterSpacing / 2;
    line-height: 1;
    margin: 0 0 20px 0;
    padding-right: 50px;
  }
  &__text {
    color: $black;
    font-size: 20px;
    line-height: 1.3;
    margin: 0 0 55px 0;
    padding-right: 20%;
  }
  &__image-wrapper {
    order: 1;
  }
  &__image {
    display: flex;
    justify-content: flex-end;
    padding: 25px 50px 0 0;
    position: relative;

    &__main {
      clip-path: url('#image-mask');
      display: block;
      height: 100%;
      position: relative;
      width: 100%;
    }
    &__floater {
      position: absolute;
      z-index: 300;
      pointer-events: none;
      touch-action: none;

      &:nth-child(1) {
        left: 40px;
        top: 15px;
      }
      &:nth-child(2) {
        bottom: 50px;
        right: 50px;
      }
      &:nth-child(3) {
        bottom: 0;
        left: 100px;
        z-index: 100;
      }
    }
  }

  @include mobile() {
    padding: 20px $horizontalPaddingMobile 90px $horizontalPaddingMobile;

    &__title {
      font-size: 50px;
      padding-right: 0;
    }
    &__text {
      padding-right: 20px;
    }
    &__image-wrapper {
      order: 0;
    }
    &__image {
      padding: 0;

      &__main {
        width: 100%;
      }
      &__floater {
        &:nth-child(1) {
          left: 0;
          top: -40px;
        }
        &:nth-child(2) {
          bottom: 10px;
          right: -20px;
        }
        &:nth-child(3) {
          bottom: -10px;
          left: 40px;
        }
      }
    }
  }
}

.slider-block--visible {
  .hero-with-text__image__floater {
    &:nth-child(1) {
      animation: floater1 3s ease-in-out infinite;
    }
    &:nth-child(2) {
      animation: floater2 3s ease-in-out infinite;
    }
    &:nth-child(3) {
      animation: floater3 3s ease-in-out infinite;
    }
    @include mobile() {
      &:nth-child(1) {
        animation-name: floaterMobile1;
      }
      &:nth-child(2) {
        animation-name: floaterMobile2;
      }
      &:nth-child(3) {
        animation-name: floaterMobile3;
      }
    }
  }
}
