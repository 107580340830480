@font-face {
  font-family: $regularFont;
  src: url('./assets/fonts/TT Commons Trial Regular.otf') format('opentype');
}
@font-face {
  font-family: $boldFont;
  src: url('./assets/fonts/TT Commons Trial Bold.otf') format('opentype');
}
@font-face {
  font-family: $demiBoldFont;
  src: url('./assets/fonts/TT Commons Trial DemiBold.otf') format('opentype');
}

@font-face {
    font-family: $ttCommons;
    src: url('./assets/fonts/tt_commons_bold-webfont.woff2') format('woff2'),
         url('./assets/fonts/tt_commons_bold-webfont.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: $ttCommons;
    src: url('./assets/fonts/tt_commons_demibold-webfont.woff2') format('woff2'),
         url('./assets/fonts/tt_commons_demibold-webfont.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: $ttCommons;
    src: url('./assets/fonts/tt_commons_medium-webfont.woff2') format('woff2'),
         url('./assets/fonts/tt_commons_medium-webfont.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: $ttCommons;
    src: url('./assets/fonts/tt_commons_regular-webfont.woff2') format('woff2'),
         url('./assets/fonts/tt_commons_regular-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;

}