@import 'variables.scss';


.timeline {
  counter-reset: timeline;

  .full-width-outer {
    background-color: $backgroundAlt3;
  }
  .full-width-inner {
    padding: 140px 0;
  }
  &__title {
    font-family: $boldFont;
    font-size: 60px;
    line-height: 64px;
    margin: 0 0 20px 0;
  }
  &__text {
    font-size: 20px;
    line-height: 1.4;
    margin: 0;
  }
  &__floater {
    margin-top: 70px;
  }
  &__items {
    margin-left: 30px;
    padding: 15px 10% 0 0;
  }
  &__item {
    border-left: 1px solid $greyMiddle;
    padding: 0 0 25px 50px;
    position: relative;

    &:last-child {
      border: none;
    }
    &:before {
      background-color: $white;
      border: 1px solid $cta;
      border-radius: 50%;
      color: $cta;
      counter-increment: timeline;
      content: counter(timeline);
      display: block;
      font-family: $demiBoldFont;
      font-size: 24px;
      height: 40px;
      left: 0;
      line-height: 41px;
      text-align: center;
      position: absolute;
      transform: translate3d(-50%, 0, 0);
      top: -5px;
      width: 40px;
    }
    &__title {
      font-family: $demiBoldFont;
      font-size: 24px;
      margin-bottom: 15px;
    }
    &__text {
      color: $greyDark;
      font-size: 20px;
      margin: 0 0 30px 0;
    }
  }

  @include mobile() {
    .full-width-inner {
      padding: 60px $horizontalPaddingMobile;
    }
    &__title {
      font-size: 35px;
      line-height: 36px;
      margin: 0 0 15px 0;
    }
    &__text {
      line-height: 1.2;
    }
    &__floater {
      margin-top: 0;
    }
    &__items {
      margin: 0 20px;
      padding: 0;
    }
    &__item {
      &:before {
        font-size: 20px;
        height: 32px;
        line-height: 33px;
        top: -3px;
        width: 32px;
      }
    }
  }
}

.slider-block--visible {
  .timeline__floater {
    animation: floater1 3s ease-in-out infinite;

    @include mobile() {
      animation-name: floaterMobile1;
    }
  }
}
