@import 'variables.scss';
@import '../MultiSelectDropdown/MultiSelectDropdown.scss';

.radio {
    margin-right: 12px;
    appearance: none;
    border: 1px solid $cta;
    width: 22px;
    height: 22px;
    border-radius: 100%;
    cursor: pointer;
    display: flex;
    align-items: center;
    flex-shrink: 0;
}

.radio:checked:after {
    content: "";
    margin: 0 auto;
    background-color: $cta;
    border-radius: 100%;
    width: 12px;
    height: 12px;
    display: block;
}