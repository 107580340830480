@import 'variables.scss';

.label {
  color: $white;
  cursor: pointer;
  display: block;
  font-size: 20px;
  line-height: 1.4;
  margin-bottom: 20px;
}
.error {
  color: $red;

  .input,
  .textarea {
    background-color: rgba($red, 0.05);
    border-color: $red;
  }
}
.input,
.textarea {
  background-color: rgba($white, 0.05);
  border-radius: 4px;
  border: 1px solid $grey;
  color: $white;
  font-family: $regularFont;
  font-size: 20px;
  height: 44px;
  line-height: 44px;
  margin-top: 3px;
  padding: 0 15px;
  transition: border-color $shortTransition ease-in-out;
  width: 100%;

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }
  @include mobile() {
    margin-top: 7px;
  }
}
.textarea {
  height: 180px;
  line-height: 1.2;
  padding: 10px 15px;
  resize: vertical;
}

@include mobile() {
  .label {
    font-size: 16px;
    line-height: 1.2;
  }
  .input {
    height: 32px;
  }
}
