@import 'variables.scss';

.reset-form-styles {
  margin-top: 0px;
  border-radius: 0;
}

.jobcenter-info-icon {
  float: right;
  position: relative;
  padding-left: 2rem;
}

.jobcenter-tooltip {
  display: none;
  position: absolute;
  background-color: $white;
  padding: 1.5rem 1rem;
  border-radius: 1rem;
  width: 327px;
  right: 0;
  filter: drop-shadow(0 20px 13px rgba(0, 0, 0, 0.03)) drop-shadow(0 8px 5px rgba(0, 0, 0, 0.08));
  color: $black;
  font-size: 18px;
  z-index: 50;
}

.jobcenter-tooltip p {
  margin: 0;
}

.jobcenter-info-icon:hover .jobcenter-tooltip {
  display: block;
}