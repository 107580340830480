@import 'variables.scss';

.label {
  color: $white;
  cursor: pointer;
  display: block;
  font-size: 20px;
  line-height: 1.4;
  margin-bottom: 20px;
}
.error {
  color: $red;

  .dropdown {
    background-color: rgba($red, 0.05);
    border: 1px solid $red;
  }
}
.dropdown {
  appearance: none;
  background-color: rgba($white, 0.05);
  background-image: url('~assets/icons/dropdown-arrow-white.svg');
  background-position: right 15px center;
  background-repeat: no-repeat;
  border-radius: 4px;
  border: 1px solid $grey;
  color: $white;
  font-family: $regularFont;
  font-size: 20px;
  height: 44px;
  line-height: 44px;
  padding: 0 15px;
  margin-top: 3px;
  transition: border-color $shortTransition ease-in-out;
  width: 100%;

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.5;
  }
  option {
    color: $black;
  }
}

@include mobile() {
  .label {
    font-size: 16px;
    line-height: 1.2;
  }
  .dropdown {
    height: 32px;
    margin-top: 7px;
  }
}
