@import 'variables.scss';

.compass-tool {
  padding: 60px 0;
  position: relative;

  &__loader {
    left: 50%;
    padding: 30px;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate3d(-50%, -50%, 0);
    width: 400px;
    z-index: 410;

    &__blocker {
      align-items: center;
      display: flex;
      height: 100vh;
      justify-content: center;
      left: 0;
      opacity: 0;
      pointer-events: none;
      position: fixed;
      top: 0;
      transition: opacity $shortTransition ease-in-out, transform $shortTransition ease-in-out;
      transform: translate3d(0, 25px, 0);
      width: 100vw;
      z-index: 400;

      &--show {
        opacity: 1;
        pointer-events: auto;
        transform: translate3d(0, 0, 0);
      }
    }
    &__heading {
      font-family: $boldFont;
      font-size: 30px;
      line-height: 40px;
      margin-bottom: 10px;
    }
    &__gears {
      justify-content: center;
      display: flex;
    }
    &__gear {
      background-image: url(~assets/gear-animation-1.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-size: 32px;
      height: 32px;
      width: 32px;

      &:nth-child(2) {
        background-image: url(~assets/gear-animation-2.svg);
      }
      &:nth-child(3) {
        background-image: url(~assets/gear-animation-3.svg);
      }
    }
  }

  .hero-with-text {
    padding: 0;
  }
  &__back {
    background-image: url('~assets/icons/back.svg');
    background-position: 15px center;
    background-repeat: no-repeat;
    background-size: 25px;
    cursor: pointer;
    display: block;
    height: 50px;
    left: 0;
    opacity: 0.7;
    position: absolute;
    top: 50%;
    transform: translate3d(-100%, -50%, 0);
    transition: opacity $shortTransition ease-in-out;
    width: 50px;

    &:hover {
      opacity: 1;
    }
  }
  section {
    height: 360px;
    position: absolute;
    opacity: 0;
    pointer-events: none;
    transition: opacity $shortTransition ease-in-out;
    width: 100%;

    &.active {
      opacity: 1;
      pointer-events: all;
    }
  }
  &__question {
    align-items: center;
    display: flex;
    height: 100%;
    position: relative;
    text-align: right;

    h2 {
      color: $black;
      font-family: $boldFont;
      font-size: 50px;
      line-height: 53px;
      margin: 0;
    }
  }
  &__answers {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
  }
  &__buttons {
    align-items: stretch;
    display: inline-flex;
    flex-direction: column;

    button:not(:first-child) {
      margin-top: 15px;
    }
  }
  &__notice {
    transform: translate3d(0, -10px, 0);
    text-align: center;

    span {
      margin-right: 5px;
    }
  }
  &__progress {
    background-color: $ctaLight;
    border-radius: 4px;
    margin: 420px auto 0 auto;
    width: 400px;

    &--nomargin {
      margin: 0 auto;
      width: calc(100% - #{$horizontalPaddingMobile * 2});
    }
    &__bar {
      background-color: $cta;
      border-radius: 4px;
      height: 8px;
    }
  }
  &__progress-text {
    margin: 10px auto 0 auto;
    max-width: 340px;
    text-align: center;
  }

  @include mobile() {
    padding: 0;

    .hero-with-text {
      padding: 0 $horizontalPaddingMobile 30px $horizontalPaddingMobile;
    }
    &__back {
      left: 50%;
      top: 0;
      transform: translate3d(-50%, 0, 0);
    }
    &__question {
      height: auto;
      padding: 60px 30px 30px 30px;
      text-align: center;

      h2 {
        font-size: 32px;
        line-height: 1.3;
      }
    }
    &__answers {
      height: auto;
      justify-content: center;
      padding: 0 $horizontalPaddingMobile 60px $horizontalPaddingMobile;
    }
  }
}
