@import 'variables.scss';

.legal {
  padding: 95px 0;

  &__title {
    color: $black;
    font-family: $demiBoldFont;
    font-size: 40px;
    line-height: 30px;
    margin: 0;
  }
  &__text {
    color: $black;
    font-size: 18px;
    line-height: 1.4;

    p {
      margin: 30px 0;
    }
  }

  @include mobile() {
    padding: 60px $horizontalPaddingMobile;

    &__title {
      margin: 0;
    }
  }
}
