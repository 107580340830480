@import 'variables.scss';

.footer-alt {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $backgroundLight;
  padding: 70px 1rem 20px 1rem;
  text-align: center;
  color: $extraDark;
  font-size: 15px;
}

.alt-logo {
    background-image: url('~assets/logo.svg');
    background-repeat: no-repeat;
    background-position: center;
    display: block;
    height: 60px;
    width: 100px;
  }

.social-icon-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
}

.social-icon-list > *:not(:first-child) {
  margin-left: 80px
}

.social-icon {
  width: 40px;
  height: 40px;
  color: $cta;
  fill: currentColor;
}

.footer-alt > *:not(:first-child) {
  margin-top: 2rem
}

.footer-alt > *:last-child {
  margin-top: 74px;
}

.footer {
  .full-width-outer {
    background-color: $black;
  }
  .full-width-inner {
    padding: 70px 0 140px 0;
    position: relative;
  }
  &__logo {
    background-image: url('~assets/logo-inverted.svg');
    background-repeat: no-repeat;
    background-position: center;
    display: block;
    height: 60px;
    width: 100px;
  }
  &__slogan {
    color: $white;
    font-size: 18px;
    line-height: 22px;
    margin-top: 25px;
    max-width: 200px;
  }
  &__list {
    margin-bottom: 40px;

    &__heading {
      color: rgba($white, 0.3);
      font-family: $boldFont;
      font-size: 14px;
      letter-spacing: $letterSpacing;
      margin-bottom: 40px;
      text-transform: uppercase;
    }
    &__item {
      color: $white;
      display: block;
      font-size: 16px;
      margin-bottom: 20px;
      text-decoration: none;

      span {
        position: relative;

        &::after {
          background-color: rgba($white, 0.6);
          bottom: -2px;
          content: '';
          display: block;
          height: 1px;
          left: 0;
          opacity: 0;
          position: absolute;
          transition: opacity $shortTransition ease-in-out;
          width: 100%;
        }
      }
      &:hover span::after {
        opacity: 1;
      }
    }
  }
  &__copyright {
    bottom: 30px;
    color: $white;
    font-size: 14px;
    left: 0;
    letter-spacing: $letterSpacing;
    position: absolute;
    text-align: center;
    text-transform: uppercase;
    width: 100%;
  }
  &__certification {
    bottom: 30px;
    position: absolute;
    right: 30px;
    text-align: right;
    width: 200px;

    &__logo {
      height: 80;
      margin-bottom: 5px;
      width: 80px;
    }
    &__text {
      color: $white;
      font-size: 14px;
    }
  }

  @include mobile() {
    .full-width-inner {
      height: auto;
      padding-bottom: 220px;
    }
    &__logo {
      margin: 0 auto;
    }
    &__slogan {
      margin: 25px auto 70px auto;
      text-align: center;
    }
    &__list {
      text-align: center;
    }
    &__copyright {
      bottom: 30px;
      font-size: 12px;
    }
    &__certification {
      bottom: 90px;
      right: 0;
      padding: 0 $horizontalPaddingMobile;
      text-align: center;
      width: 100%;

      &__logo {
        height: 104px;
        width: 105px;
      }
    }
  }
}
