@import 'variables.scss';

.bootcamps-hero {
    height: 450px;
    @include mobile() {
        height: 400px;
    }

    .bootcamps-image {
        height: 100%;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
    }

    .bootcamps-hero-container {
        max-width: 1059px;
        margin: 0 auto;
        color: white;
        padding: 0 1rem;
        display: flex;
        align-items: center;
        height: 100%;

        .small-container {
            max-width: 517px;

            @include mobile() {
                text-align: center;
            }

            h1 {
                font-weight: 700;
                font-size: 70px;
                text-align: left;
                line-height: 0.9;
                margin: 0;
                margin-bottom: 1rem;
                @include mobile() {
                    text-align: center;
                    font-size: 50px;
                }
            }

            p {
                font-weight: 400;
                font-size: 20px;
                line-height: 1.4;
                text-align: left;
                margin-bottom: 1rem;
                @include mobile() {
                    text-align: center;
                }
            }
        }
    }
}