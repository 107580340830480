@import 'variables.scss';

.partner-hero {
  overflow: hidden;
  padding: 70px 0 130px 0;

  &__logo {
    display: block;
    margin-bottom: 35px;
    max-height: 200px;
    max-width: 100%;
  }
  &__name {
    color: $black;
    font-family: $boldFont;
    font-size: 34px;
    line-height: 53px;
    margin: 0 0 20px 0;
  }
  &__text {
    color: $black;
    font-size: 20px;
    line-height: 1.4;
    margin-bottom: 40px;
  }
  &__image-wrapper {
    order: 1;
  }
  &__image {
    display: flex;
    justify-content: center;
    position: relative;

    &__main {
      clip-path: url('#image-mask-alt');
      width: calc(100% - 100px);
      z-index: 200;

      img {
        display: block;
        transform: scale(1);
        transition: transform $mediumTransition ease-in-out;
        width: 100%;
      }
    }
    &__background {
      background-color: $cta;
      clip-path: url('#image-mask-alt');
      height: calc(100% - 20px);
      left: 80px;
      position: absolute;
      top: 20px;
      transform: rotate(8deg);
      transition: transform $mediumTransition ease-in-out;
      width: calc(100% - 100px);
      z-index: 100;

      &::after {
        background-color: $white;
        clip-path: url('#image-mask-alt');
        content: '';
        height: calc(100% - 2px);
        left: 1px;
        opacity: 1;
        position: absolute;
        top: 1px;
        transition: opacity $mediumTransition ease-in-out;
        width: calc(100% - 2px);
        z-index: 150;
      }
    }
    &:hover &__main img {
      transform: scale(1.05);
    }
    &:hover &__background {
      transform: rotate(12deg);

      &:after {
        opacity: 0;
      }
    }
  }

  @include mobile() {
    padding: 20px $horizontalPaddingMobile 90px $horizontalPaddingMobile;

    &__logo {
      margin: 0 auto 35px auto;
    }
    &__name {
      font-size: 28px;
      text-align: center;
    }
    &__text {
      text-align: center;
    }

    &__image-wrapper {
      order: 0;
    }
    &__image {
      margin-bottom: 30px;

      &__main {
        width: 100%;
      }
      &__background {
        left: 0;
        width: 100%;
      }
    }
  }
}
