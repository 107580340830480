@import 'variables.scss';


.ultra-header-container {
  position: relative;
}

.ultra-header {
  margin-top: -100px;
  position: relative;
}

.z-20 {
  z-index: 20;
}

.ultra-image-background {
  min-height: 570px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  min-width: 100%;

  @include mobile() {
    min-height: 640px;
  }
}

.ultra-container {
  max-width: 1062px;
  margin: 0 auto;
  padding: 0 2rem;
}

.ultra-logo {
  padding-top: 23px;
}

.ultra-title-container {
  padding-top: 88px;
  max-width: 500px;
}

.ultra-title-container span {
  color: $white;
}

.ultra-title {
  font-weight: 700;
  font-size: 68px;
  line-height: 0.90em;
}

.ultra-subtitle {
  font-size: 24px;
  margin-top: 67px;
  max-width: 500px;

  @include mobile() {
    display: none;
  }
}

.ultra-subtitle li:not(:last-child), .ultra-subtitle-mobile li:not(:last-child) {
  margin-bottom: 1rem;
}

.ultra-subtitle-mobile {
  display: none;
  font-size: 20px;
  color: $white;
  margin-top: 70px;
  padding-bottom: 67px;

  @include mobile() {
    display: block;
  }
}

.ultra-subtitle li::marker {
  color: $cta;
}

.ultra-subtitle ul {
  margin: 0;
}

.ultra-form-container {
  position: absolute;
  top: 250px;
  right: 108px;
  max-width: 524px;
  width: 100%;

  @include mobile() {
    position: relative;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    max-width: none;
  }
}