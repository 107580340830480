@import 'variables.scss';

.bg-yellow-light {
    background-color: $yellowLight;
}

.bg-blue-light {
    background-color: $blueLight;
}

.bg-orange-light {
    background-color: $orangeLight;
}

.bg-navy-light {
    background-color: $navyLight;
}

.bg-pink-light {
    background-color: $pinkLight;
}

.bg-green-light {
    background-color: $greenLight;
}

.tech-mentor-hero {
    height: 700px;
    font-family: $ttCommons;
}

.community-container {
    margin-top: - 270px;
    margin-bottom: - 120px;
    @include mobile() {
        margin-top: 0px;
        margin-bottom: 0px;
    }
}

.hero-title {
    font-weight: 700;
    font-size: 70px;
    text-align: center;
    line-height: 0.9;
    margin: 0;
    margin-bottom: 1rem;
    color: $white;
    @include mobile() {
        font-size: 50px;
    }
}

.techmentor-hero-container {
    text-align: center;
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    justify-items: center;
    padding: 0 24px;
}

.techmentor-hero-container > * {
    flex-grow: 1;
}

.mentor-section {
    background-color: $backgroundLight;

    h2 {
        font-weight: 700;
        font-size: 45px;

        @include mobile() {
            font-size: 35px;
        }
    }

    .mentor-section-content {
        margin-top: 40px;
        font-size: 20px;
    }
}

.mentor-section-container {
    padding: 72px 24px 84px 24px;
    max-width: 820px;
    margin: 0 auto;
    text-align: center;
}

.mentor-container {
    position: relative;
    z-index: 20;
    max-width: 1066px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(2, minmax(0, 1fr));
    padding: 0 22px;
    margin-top: 152px;
    @include mobile() {
        grid-template-columns: repeat(1, minmax(0, 1fr));
    }
}

.mentorship-course-card {
    margin-bottom: 105px;
    border-radius: 4px;
    padding: 45px 24px 22px 48px;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    &:hover {
        filter: drop-shadow(0 4px 20px rgba(0, 0, 0, 0.03)) drop-shadow(0 0px 8px rgba(0, 0, 0, 0.08));
    }

    &:nth-child(odd) {
        margin-right: 11px;

        @include mobile() {
            margin-right: 0;
        }
    }

    &:nth-child(even) {
        margin-left: 11px;
        
        @include mobile() {
            margin-left: 0;
        }
    }
}

.mentorship-course-card-icon {
    position: absolute;
    top: -44px;
    max-width: 147px;
    max-height: 147px;
        
    @include mobile() {
        margin: 0 auto;
        left: 0;
        right: 0;
    }
}

.mentorship-course-card-button {
    display: flex;
        
    @include mobile() {
        display: none;
    }
}

.mentorship-course-card-small-button {
    display: none;
    margin-top: 29px;
        
    @include mobile() {
        display: flex;
    }
}

.mentorship-course-card-button * {
    margin-left: auto;
}

.mentorship-course-card-name {
    font-weight: 600;
    font-size: 36px;
    margin: 0;
    margin-top: 43px;
}

.mentorship-course-card-description {
    margin-top: 17px;
    font-size: 24px;
        
    @include mobile() {
        font-size: 20px;
    }
}

.mentorship-course-card-description * {
    margin: 0;
}

.mentorship-course-card-optional-certificate-container {
    display: flex;
    margin-top: 2rem;
}

.mentorship-course-card-optional-certificate-title {
    font-weight: 600;
    font-size: 20px;
    margin: 0;
    line-height: 1;
        
    @include mobile() {
        font-size: 20px;
    }
}

.mentorship-course-card-optional-certificate-subtitle {
    font-size: 18px;
    opacity: 0.7;
    margin: 0;
}

.mentorship-course-card-optional-certificate-image {
    width: 33px;
    height: 33px;
    margin-right: 16px;
    flex-shrink: 0;
}

.providers-section {
    padding-top: 88px;
}

.hidden {
    display: none;
}

.visible {
    display: block;
}