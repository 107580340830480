@import 'variables.scss';

$planePath: 'M-14.3523 556.79C108.471 500.059 244.484 420.555 585.499 403.952C974.132 385.031 1197.18 229.033 1236.7 209.612';

.easy-steps {
  .full-width-outer {
    background-color: $backgroundAlt;
  }
  .full-width-inner {
    margin-bottom: 140px;
    padding: 80px 0 140px 0;
    position: relative;
  }
  &__title {
    color: $black;
    font-family: $boldFont;
    font-size: 60px;
    line-height: 64px;
    margin: 0 0 5px 0;
  }
  &__text {
    color: $black;
    font-family: $demiBoldFont;
    font-size: 28px;
    line-height: 1.4;
    margin: 0 0 80px 0;
  }
  &__item {
    color: $black;
    opacity: 0;
    position: relative;
    margin-bottom: 60px;
    transition: opacity $mediumTransition ease-in-out;

    &--active {
      opacity: 1;
    }
    &__number {
      color: $black;
      font-family: $boldFont;
      font-size: 120px;
      line-height: 100px;
      margin-bottom: 10px;
    }
    &__title {
      font-family: $demiBoldFont;
      font-size: 24px;
      margin-bottom: 15px;
      max-width: 250px;
    }
    &__logo {
      background-image: url('~assets/logo-outline.png');
      background-position: center;
      background-repeat: no-repeat;
      height: 80px;
      left: 20px;
      position: absolute;
      top: 25px;
      width: 120px;
    }
  }
  &__content {
    position: relative;
    z-index: 200;
  }
  &__plane-path-fader {
    background: linear-gradient(90deg, $backgroundAlt 50%, rgba($backgroundAlt, 0) 100%);
    display: block;
    height: 100%;
    left: calc(((100vw - 100%) / 2) * -1);
    position: absolute;
    top: 0;
    width: calc(100%/12 + ((100vw - 100%) / 2));
    z-index: 150;
  }
  &__plane-path {
    left: 0;
    opacity: 0;
    position: absolute;
    top: -40px;
    z-index: 100;
  }
  &__plane {
    left: -20px;
    motion-path: path($planePath);
    offset-path: path($planePath);
    position: absolute;
    top: -70px;
    transform: rotate(40deg);
    z-index: 200;
  }
  &.slider-block--activated &__plane-path {
    opacity: 1;
  }

  @include mobile() {
    .full-width-inner {
      margin-bottom: 140px;
      padding: 60px $horizontalPaddingMobile 140px $horizontalPaddingMobile;
    }
    &__title {
      font-size: 35px;
      line-height: 36px;
      margin: 0 0 15px 0;
    }
    &__text {
      line-height: 1.2;
      margin: 0 0 60px 0;
    }
    &__item {
      &__number {
        text-align: center;
      }
      &__title {
        max-width: none;
        text-align: center;
      }
      &__logo {
        left: 50%;
        top: 25px;
      }
    }
    &__plane-path-fader,
    &__plane-path,
    &__plane {
      display: none;
    }
  }
}
