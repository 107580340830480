@import 'variables.scss';

.cookie-banner {
  bottom: 30px;
  left: 0;
  position: fixed;
  z-index: 800;

  .full-width-inner {
    position: relative;

    @include mobile() {
      padding: 0 $horizontalPaddingMobile;
    }
  }
  &__inner {
    backdrop-filter: blur(10px);
    background-color: rgba($white, 0.85);
    border-radius: 4px;
    border: 1px solid #C4C4C4;
    color: $black;
    font-size: 20px;
    line-height: 24px;
    padding: 20px 20px 10px 20px;
    width: 100%;
  }
  &__image {
    align-items: center;
    display: flex;
    font-size: 50px;
    justify-content: center;
    height: 100%;
  }
  &__more {
    display: inline-block;
    font-family: $boldFont;
    font-size: 14px;
    line-height: 24px;
    margin-left: 5px;
    vertical-align: top;
  }
  &__buttons {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;

    @include mobile() {
      justify-content: center;
      margin-top: 15px;
    }
    button, div {
      margin: 0 10px 10px 10px;
    }
  }
}
