@import 'variables.scss';

.jobcenter {
  .full-width-outer {
    background-color: $backgroundAlt;

    &:nth-child(3) {
      background-color: $background;
    }
  }
  .full-width-inner {
    position: relative;
  }
  &__title {
    color: $black;
    font-family: $boldFont;
    font-size: 50px;
    line-height: 60px;
    margin: 110px 0 60px 0;
    text-align: center;
  }
  &__text {
    color: $black;
    font-size: 24px;
    line-height: 30px;
    margin: 0 0 50px 0;
    text-align: center;
  }
  &__bottom-wrapper {
    height: 480px;
    padding-top: 100px;
    position: relative;
  }
  &__bottom-group {
    position: absolute;
    width: 100%;
  }
  &__bottom {
    background: $cta;
    border-radius: 4px;
    height: 180px;
    margin: 0 0 100px 0;
    z-index: 200;

    .row {
      height: 100% !important;
    }
    &__floater {
      left: 80px;
      position: absolute;
      top: -60px;
    }
    > div > div {
      align-self: center;
    }
    &__title {
      color: $white;
      font-family: $demiBoldFont;
      font-size: 36px;
      line-height: 39px;
    }
    &__item {
      color: $white;
      font-size: 24px;
      line-height: 35px;

      strong {
        font-family: $demiBoldFont;
      }
    }
    &__logo {
      display: block;
      margin-left: 20%;
      height: 104px;
      width: 105px;
    }
  }
  &__contact-info {
    color: $black;
    font-size: 30px;
    line-height: 50px;
    margin: 0;
    padding: 140px 0 80px 0;
    text-align: center;

    a {
      color: $cta;
      text-decoration: none;
    }
  }
  &__company-name {
    color: $black;
    font-family: $boldFont;
    font-size: 34px;
    line-height: 30px;
    margin: 100px 0 40px 0;
  }
  &__company-info {
    color: $black;
    font-size: 24px;
    line-height: 34px;
    margin: 0 0 100px 0;

    a {
      color: $cta;
      text-decoration: none;
    }
  }

  @include mobile() {
    .full-width-inner {
      padding: 0 $horizontalPaddingMobile;
    }
    &__title {
      font-size: 40px;
      line-height: 45px;
      margin-bottom: 40px;
    }
    &__bottom-wrapper {
      height: 1060px;
      padding-top: 0;
    }
    &__bottom {
      height: auto;
      margin: 60px #{10px - $horizontalPaddingMobile} 0 #{10px - $horizontalPaddingMobile};
      padding: 140px 12px 60px 12px;

      &__floater {
        left: 70px;
        top: -50px;
      }
      &__title {
        padding: 10px 20px;
        max-width: 100%;
        text-align: center;
      }
      &__logo {
        margin-left: calc(50% - 105px/2);
        margin-top: 15px;
      }
    }
    &__contact-info {
      font-size: 20px;
      line-height: 1.3;
    }

  }
}

.slider-block--visible {
  .jobcenter__bottom__floater {
    animation: floater1 3s ease-in-out infinite;
  }
  .jobcenter__bottom__floater-alt {
    animation: floater3 3s ease-in-out infinite;
  }
}
