@import 'variables.scss';

.bootcamps__page {
    margin: 0 auto;
    max-width: 1080px;
    width: 100%;
    padding: 0rem 1rem 2rem 1rem;

    .purple-circle {
        color: $cta;
        margin-right: 0.5rem;
    }

    p {
        margin: 0;
    }

    ul {
        list-style: none;
        margin: 0;
        padding: 0;
    }

    h1 {
        font-weight: 700;
        font-size: 70px;
        text-align: center;
        margin-bottom: 72px;
        margin-top: 96px;
    }

    .bootcamp-list-description {
        font-size: 20px;
        text-align: center;
        margin-bottom: 92px;
        margin-top: -48px;
    }

    .filters {
        display: flex;
        flex-direction: row-reverse;
        @include mobile() {
            display: flex;
            flex-direction: column-reverse;
            > div:not(:first-child) {
                margin-bottom: 38px;
            }
        }
        margin-bottom: 72px;
        
        > div:not(:first-child) {
            margin-right: 30px;
        }
    }

    .w-1\/3 {
        width: 100%;
    }

    .partner-logo {
        width: auto;
        height: auto;
        max-height: 48px;
        max-width: 50%;
        padding-left: 1rem;
    }

    .bootcamp {
        &__list {
            display: grid;

            > li {
                margin-bottom: 1.5rem;
            }

            > li:nth-child(odd) {
                margin-right: 1.25rem;
            }

            min-height: 511px;
            grid-template-columns: repeat(2, minmax(0, 1fr));
            list-style: none;
            @include mobile() {
                grid-template-columns: repeat(1, minmax(0, 1fr));

                > li:nth-child(odd) {
                    margin-right: 0rem;
                }
            }
        }
        &__item {
            background-color: $backgroundAlt2;
            border-radius: 4px;
            border: 1px solid $greyMiddle;
            padding: 30px 1.5rem;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
        }
        &__small_header {
            font-weight: 600;
            font-size: 20px;
            margin-top: 1rem;
        }
        &__big_header {
            font-weight: 600;
            font-size: 28px;
        }
        &__footer {
            margin-top: 48px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            @include mobile() {
                flex-direction: column;
                align-items: flex-start;
                > *:first-child {
                    margin-bottom: 2rem;
                }
            }
            a {
                display: block;
                padding: 14px 20px;
                border: 1px solid $cta;
                color: $cta;
                font-weight: 700;
                font-size: 18px;
                text-transform: uppercase;
                letter-spacing: 0.05em;
                text-decoration: none;
                border-radius: 4px;
            }
        }
        &__header {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 1rem;
        }
    }

    .md-text {
        color: $greyDark;
        font-weight: 500;
        font-size: 20px;
    }

    .pill {
        color: white;
        padding: 2px 12px;
        border-radius: 6px;
        font-size: 18px;
        line-height: 24px;
        margin-bottom: 0.5rem;
    }

    .pill-container {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 1.5rem;

        > p:not(:last-child) {
            margin-right: 0.5rem;
        }
    }

    .base-text {
        margin-top: 1rem;
        font-size: 18px;
        line-height: 1.4;
        color: $greyDark;
    }

    .location-container {
        display: flex;
        align-items: center;
    }
}

  