@import 'variables.scss';

.apply-now-form {
  position: relative;

  .full-width-outer {
    height: calc(360px + 10vw);

    &::before,
    &::after {
      content: '';
      position: absolute;
      left: 0;
      width: 100vw;
      z-index: 100;
    }
    &::before {
      background-color: $backgroundAlt;
      top: 0;
      height: 360px;
    }
    &::after {
      background: linear-gradient(-175deg, $backgroundAlt 50%, $white 50%);
      bottom: 0;
      height: 10vw;
    }
  }
  .full-width-inner {
    padding-top: 100px;
  }
  &__title {
    color: $black;
    font-family: $demiBoldFont;
    font-size: 60px;
    line-height: 70px;
    margin: 0;
    position: relative;
    text-align: center;
    z-index: 200;
  }
  &__form {
    background-color: $cta;
    border-radius: 4px;
    margin-bottom: -100px;
    padding: 70px 10% 100px 10%;
    position: relative;
    top: calc(-60px - 10vw);
    z-index: 300;

    &__text {
      color: $white;
      font-family: $demiBoldFont;
      font-size: 24px;
      line-height: 30px;
      margin-bottom: 55px;
      padding-right: 15%;
    }
    &__button {
      display: flex;
      justify-content: center;
      padding-top: 50px;
    }
    &__checkboxes {
      margin-top: 30px;
    }
    &__error {
      color: $red;
      position: relative;
      top: -15px;
    }
    &__message {
      color: $white;
      font-family: $demiBoldFont;
      font-size: 24px;
      line-height: 30px;
    }
    &__floater {
      position: absolute;

      &:nth-child(1) {
        top: -40px;
        right: -30px;
      }
      &:nth-child(2) {
        bottom: 40px;
        left: -75px;
      }
    }
  }

  @include mobile() {

    .full-width-outer {
      height: auto;

      &::after {
        display: none;
      }
    }
    .full-width-inner {
      padding: 80px $horizontalPaddingMobile 0 $horizontalPaddingMobile;
    }

    &__title {
      font-size: 40px;
      line-height: 45px;
      margin-bottom: 60px;
    }
    &__form {
      border-radius: 0;
      margin-bottom: 0;
      padding: 70px $horizontalPaddingMobile 100px $horizontalPaddingMobile;
      top: 0;

      &__text {
        margin-bottom: 35px;
        padding-right: 0;
      }
      &__floater {
        &:nth-child(1) {
          top: -60px;
          right: 0;
        }
        &:nth-child(2) {
          display: none;
        }
      }
    }
  }
}

.slider-block--visible {
  .apply-now-form__form__floater {
    &:nth-child(1) {
      animation: floater1 3s ease-in-out infinite;

      @include mobile() {
        animation-name: floaterMobile1Alt;
      }
    }
    &:nth-child(2) {
      animation: floater2 3s ease-in-out infinite;

      @include mobile() {
        animation: none;
      }
    }
  }
}
