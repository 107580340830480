@import 'variables.scss';

.providers {
  position: relative;
  z-index: 150;

  .full-width-inner {
    padding: 60px 0 100px 0;
  }
  &-alt {
    .full-width-outer {
      background-color: $background;
    }
  }
  &__title {
    color: $black;
    font-family: $boldFont;
    font-size: 60px;
    line-height: 64px;
    margin: 0 0 25px 0;
  }
  &__text {
    color: $black;
    font-size: 20px;
    line-height: 1.4;
    padding-bottom: 60px;
    padding-right: 20%;
  }
  &__logos {
    &__item {
      align-items: center;
      display: flex;
      justify-content: center;
      height: 100%;
      padding: 15px;
      position: relative;

      img {
        display: block;
        max-height: 80px;
        max-width: 100%;
      }
      &__tooltip-wrapper {
        padding: 50px 50px 60px 50px;
      }
      &__tooltip {
        background-color: $cta;
        bottom: 0;
        clip-path: url('#image-mask-small-mirror');
        opacity: 0;
        pointer-events: none;
        position: absolute;
        right: 0;
        transform: translate3d(85%, 90%, 0) rotate(15deg) scale(0.7);
        transform-origin: left top;
        transition: opacity $mediumTransition ease-in-out, transform $shortTransition ease-in;
        z-index: 500;

        &__description {
          color: $white;
          font-size: 20px;
          line-height: 1.2;
          margin-bottom: 20px;
          width: 275px;
        }
        &__button {
          display: flex;
          justify-content: center;
        }
      }
      &:hover &__tooltip {
        opacity: 1;
        pointer-events: auto;
        transform: translate3d(85%, 90%, 0) rotate(0deg) scale(1);
      }
    }
  }

  @include mobile() {
    .full-width-inner {
      padding: 60px $horizontalPaddingMobile;
    }
    &__title {
      font-size: 35px;
      line-height: 36px;
      margin: 0 0 15px 0;
    }
    &__text {
      line-height: 1.2;
      padding-bottom: 40px;
      padding-right: 10%;
    }
    &__logos {
      position: relative;

      &__item {
        margin-bottom: 10px;

        &__tooltip-wrapper {
          padding: 50px 40px 60px 40px;
        }
        &__tooltip {
          background-color: rgba($cta, 0.9);
          bottom: 15%;
          position: fixed;
          transform: translate3d(0, 0, 0);
        }
        &:hover &__tooltip {
          transform: translate3d(10px, 0, 0) rotate(0deg) scale(1);
        }
      }
    }
  }
}
