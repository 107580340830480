@import 'variables.scss';

.blog-list {
  .full-width-outer {
    background-color: $backgroundAlt;
    position: relative;

    &::after {
      background: linear-gradient(185deg, $backgroundAlt 50%, $white 50%);
      bottom: 0;
      content: '';
      position: absolute;
      left: 0;
      height: 10vw;
      width: 100vw;
    }
  }
  .full-width-inner {
    padding: 120px 0 10vw 0;
    position: relative;
  }
  &__title {
    color: $black;
    font-family: $boldFont;
    font-size: 60px;
    line-height: 70px;
    margin: 0 0 40px 0;
    text-align: center;
  }
  &__items {
    margin-bottom: calc(-10vw + 100px);
    position: relative;
    top: -10vw;
  }
  &__item {
    background: $white;
    border-radius: 4px;
    border: 1px solid $grey;
    display: block;
    margin-bottom: 25px;
    padding: 40px 50px;
    text-decoration: none;
    transition: border-color $shortTransition ease-in-out;

    &__title {
      color: $black;
      font-family: $demiBoldFont;
      font-size: 30px;
      line-height: 36px;
      margin-bottom: 15px;
    }
    &__date {
      color: $grey;
      font-family: $demiBoldFont;
      font-size: 18px;
      line-height: 22px;
      margin-bottom: 10px;
    }
    &__text {
      color: $black;
      line-height: 22px;
      font-size: 18px;
    }
    &:hover {
      border-color: $greyDark;
    }
  }

  @include mobile() {
    .full-width-outer {
      &::after {
        background: linear-gradient(-170deg, $backgroundAlt 50%, $white 50%);
        height: 20vw;
      }
    }
    .full-width-inner {
      padding: 60px 0 20vw 0;
    }
    &__title {
      font-size: 40px;
      line-height: 45px;
      margin-bottom: 60px;
    }
    &__items {
      margin-bottom: calc(-20vw + 60px);
      padding: 0 $horizontalPaddingMobile;
      top: calc(-20vw - 40px);
    }
    &__item {
      margin-bottom: 20px;
      padding: 40px 20px 30px 20px;

      &__title {
        font-size: 24px;
        line-height: 32px;
      }
    }
  }
}
