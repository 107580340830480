@import 'variables.scss';

.button {
  background-color: $cta;
  border-radius: 4px;
  border: none;
  color: $white;
  cursor: pointer;
  display: inline-block;
  font-family: $boldFont;
  font-size: 18px;
  letter-spacing: $letterSpacing;
  line-height: 24px;
  padding: 9px 20px 7px 20px;
  text-align: center;
  text-decoration: none;
  text-transform: uppercase;
  transition: background-color $shortTransition ease-in-out;
  white-space: nowrap;

  @include mobile() {
    font-size: 16px;
  }
  &:hover {
    background-color: $ctaDark;
  }
  &--disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
  &--busy {
    animation: busyFade 1s ease-in-out infinite;
    cursor: wait;
  }
  &--small {
    font-size: 16px;
  }
  &--wordy {
    white-space: normal;
  }
  &--wide {
    padding-left: 50px;
    padding-right: 50px;
  }
  &--large {
    padding: 1rem 1.5rem;
  }
  &--transparent-blue {
    background-color: rgba($ctaLight, 0);
    border: 1px solid $cta;
    color: $cta;

    &:hover {
      background-color: $ctaLight;
    }
  }
  &--transparent-blue-alt,
  &--transparent-blue-alt2 {
    background-color: $white;
    border: 1px solid $cta;
    color: $cta;

    &:hover {
      background-color: $ctaLight;
    }
  }
  &--transparent-blue-alt2 {
    border: 1px solid transparent;
  }
  &--transparent-white {
    background-color: rgba($white, 0);
    border: 1px solid $white;
    color: $white;

    &:hover {
      background-color: rgba($white, 0.1);
    }
  }
}

@keyframes busyFade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
