@import 'variables.scss';

.landing-page {
  position: relative;

  &__hero {
    .full-width-outer {
      background-color: $backgroundAlt;
    }
    &__content {
      padding: 120px 0 140px 0;
      position: sticky;
      top: 100px;
      z-index: 200;

      &::before,
      &::after {
        content: '';
        left: calc(((100vw - #{$maxWidth / 12 * 10}) / 2) * -1);
        position: absolute;
        width: 100vw;
        z-index: 100;
      }
      &::before {
        background: $white;
        bottom: -20vw;
        height: 20vw;
      }
      &::after {
        background: linear-gradient(175deg, $backgroundAlt 50%, $white 50%);
        bottom: 0;
        height: 10vw;
      }
      &__text {
        color: $black;
        font-size: 24px;
        line-height: 1.4;
        margin: 0;
        position: relative;
        z-index: 200;

        li {
          list-style: none;
          margin-bottom: 10px;
          padding-left: 35px;
          position: relative;

          &::before {
            background-color: $cta;
            border-radius: 50%;
            content: '';
            height: 10px;
            left: 4px;
            position: absolute;
            top: 11px;
            width: 10px;
          }
        }
      }
    }
    &__form {
      background-color: $cta;
      border-radius: 4px;
      margin-top: 80px;
      padding: 60px 40px;
      position: relative;
      z-index: 300;

      &__text {
        color: $white;
        font-family: $demiBoldFont;
        font-size: 24px;
        line-height: 30px;
        margin-bottom: 30px;
      }
      &__button {
        display: flex;
        justify-content: center;
        padding-top: 30px;
      }
      &__error {
        color: $red;
        position: relative;
        top: -15px;

        p {
          margin: 0;
        }
      }
      &__message {
        color: $white;
        font-family: $demiBoldFont;
        font-size: 24px;
        line-height: 30px;
      }
      &__checkboxes {
        margin-top: 30px;
      }
      &__floater {
        position: absolute;

        &:nth-child(1) {
          top: -10px;
          right: -60px;
        }
        &:nth-child(2) {
          left: -50px;
          top: 50%;
        }
        &:nth-child(3) {
          bottom: -50px;
          right: 30px;
        }
      }
    }
  }
  &__partners {
    padding: 220px 0 140px 0;
    position: relative;
    z-index: 300;

    &__title {
      font-family: $boldFont;
      font-size: 40px;
      line-height: 1.2;
      text-align: center;
      margin: 0 0 100px 0;
    }
    &__images {
      &__item {
        align-items: center;
        display: flex;
        justify-content: center;
        height: 100%;
        padding: 15px;

        img {
          display: block;
          max-height: 80px;
          max-width: 100%;
        }
      }
    }
  }

  @include mobile() {
    &__hero {
      .full-width-inner {
        padding: 0 $horizontalPaddingMobile 80px $horizontalPaddingMobile;
      }
      &__content {
        padding: 0;
        position: relative;
        top: 60px;

        &::before {
          bottom: -50vh;
          height: 50vh;
          left: -$horizontalPaddingMobile;
        }
        &::after {
          bottom: -75px;
          height: 75px;
          left: -$horizontalPaddingMobile;
        }
        &__text {
          font-size: 20px;
          line-height: 24px;

          ul {
            margin-top: 60px;
            padding: 0 30px 0 10px;

            li::before {
              top: 7px;
            }
          }
        }
      }
      &__form {
        margin-top: 160px;
        padding: 40px $horizontalPaddingMobile;
        top: 0;

        &__text {
          margin-bottom: 35px;
          padding-right: 0;
        }
        &__floater {
          &:nth-child(1) {
            top: -80px;
            right: 0;
          }
          &:nth-child(2) {
            display: none;
          }
          &:nth-child(3) {
            right: -10px;
          }
        }
      }
    }
    &__partners {
      padding: 80px 0;

      &__title {
        font-size: 24px;
        padding: 0 50px;
        margin-bottom: 40px;
      }
      &__images {
        &__item {
          padding: 15px 30px;

          img {
            max-height: 60px;
          }
        }
      }
    }
    .learn-more {
      .full-width-inner {
        padding-bottom: 60px;
        padding-top: 100px;
      }
      &__button {
        padding-top: 20px;
      }
    }
  }
}

.slider-block--visible {
  .landing-page__hero__form__floater {
    &:nth-child(1) {
      animation: floater1 3s ease-in-out infinite;

      @include mobile() {
        animation-name: floaterMobile1Alt;
      }
    }
    &:nth-child(2) {
      animation: floater2 3s ease-in-out infinite;

      @include mobile() {
        animation: none;
      }
    }
    &:nth-child(3) {
      animation: floater3 3s ease-in-out infinite;

      @include mobile() {
        animation-name: floaterMobile3;
      }
    }
  }
}
