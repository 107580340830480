@import 'variables.scss';

.events {
  .full-width-outer {
    background-color: $backgroundAlt;
    position: relative;

    &::after {
      background: linear-gradient(175deg, $backgroundAlt 50%, $white 50%);
      bottom: 0;
      content: '';
      position: absolute;
      left: 0;
      height: 10vw;
      width: 100vw;
    }
  }
  .full-width-inner {
    padding: 95px 0 calc(10vw + 130px) 0;
  }
  &__title {
    color: $black;
    font-family: $demiBoldFont;
    font-size: 60px;
    line-height: 64px;
    margin: 0 0 30px 0;
    text-align: center;
  }
  &__text {
    color: $black;
    font-size: 24px;
    line-height: 30px;
    margin: 0;
    text-align: center;
  }
  &__items {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 70px;
    padding: 0 25px;
    position: relative;
    top: -200px;
  }
  &__event {
    border: 1px solid $grey;
    border-radius: 4px;
    margin: 0 15px 30px 15px;
    overflow: hidden;
    text-decoration: none;
    width: calc(100%/3 - 60px);

    &__image {
      background-size: cover;
      height: 180px;
    }
    &__info {
      padding: 0 15px 10px 15px;
    }
    &__type {
      color: $greyDark;
      font-size: 18px;
      line-height: 22px;
      margin: 25px 0 5px 0;
    }
    &__title {
      color: $black;
      font-family: $demiBoldFont;
      font-size: 24px;
      line-height: 1.2;
      margin-bottom: 15px;
    }
    &__datetime,
    &__location {
      background-repeat: no-repeat;
      color: $black;
      font-size: 18px;
      margin-bottom: 10px;
      padding-left: 25px;
    }
    &__datetime {
      background-image: url('~assets/icons/time.svg');
      background-position: 0 3px;
    }
    &__location {
      background-image: url('~assets/icons/location.svg');
      background-position: 0 0;
    }
  }

  @include mobile() {
    .full-width-outer {
      &::after {
        background: linear-gradient(172deg, $backgroundAlt 50%, $white 50%);
        height: 15vw;
      }
    }
    .full-width-inner {
      padding-left: $horizontalPaddingMobile;
      padding-right: $horizontalPaddingMobile;
    }
    &__title {
      font-size: 40px;
      line-height: 45px;
    }
    &__items {
      padding: 0 10px 150px 10px;
      top: -50px;
    }
    &__event {
      margin: 0 0 30px 0;
      width: 100%;
    }
  }
}
