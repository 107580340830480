@import 'variables.scss';

$rightPadding: 200px;

.compass-tool-results {
  position: relative;

  &__intro {
    background-color: $backgroundAlt;
    clip-path: url('#background-mask-mirror');
    left: 50%;
    max-width: 1000px;
    padding: 60px 50px 100px 50px;
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translate3d(-50%, -45%, 0);
    width: calc(100% - 40px);
    z-index: 410;

    &__blocker {
      align-items: center;
      background-color: rgba($grey, 0.9);
      display: flex;
      height: 100vh;
      justify-content: center;
      left: 0;
      opacity: 0;
      pointer-events: none;
      position: fixed;
      top: 0;
      transition: opacity $shortTransition ease-in-out, transform $shortTransition ease-in-out;
      transform: translate3d(0, 25px, 0);
      width: 100vw;
      z-index: 400;

      &--show {
        opacity: 1;
        pointer-events: auto;
        transform: translate3d(0, 0, 0);
      }
    }
    &__heading {
      font-family: $boldFont;
      font-size: 30px;
      line-height: 40px;
      margin-bottom: 30px;
      padding: 0 15%;
    }
    &__text {
      margin-bottom: 30px;
      padding: 0 15%;

      p {
        font-size: 24px;
        line-height: 30px;
        margin: 0 0 15px 0;
      }
    }
    &__col-heading {
      font-family: $demiBoldFont;
      font-size: 24px;
      line-height: 30px;
      padding: 0 3%;
    }
    &__col-text {
      color: $greyDark;
      font-size: 20px;
      line-height: 1.2;
      margin-bottom: 30px;
      padding: 0 3%;
    }
  }
  &__form {
    background-color: $cta;
    border-radius: 4px;
    padding: 40px 50px;
    position: relative;
    width: 560px;
    z-index: 300;

    &__close {
      cursor: pointer;
      opacity: 0.7;
      position: absolute;
      right: 10px;
      top: 10px;
      transition: opacity $shortTransition ease-in-out;

      &:hover {
        opacity: 1;
      }
    }
    &__text {
      color: $white;
      font-family: $demiBoldFont;
      font-size: 20px;
      line-height: 1.3;
      margin-bottom: 30px;
      padding-right: 15%;
    }
    &__info {
      color: $white;
      font-size: 16px;
      line-height: 1.3;
      margin-top: 20px;
    }
    &__button {
      text-align: center;
    }
    &__blocker {
      align-items: center;
      background-color: rgba($white, 0.9);
      display: flex;
      height: 100vh;
      justify-content: center;
      left: 0;
      opacity: 0;
      pointer-events: none;
      position: fixed;
      top: 0;
      transition: opacity $shortTransition ease-in-out, transform $shortTransition ease-in-out;
      transform: translate3d(0, 25px, 0);
      width: 100vw;
      z-index: 400;

      &--show {
        opacity: 1;
        pointer-events: auto;
        transform: translate3d(0, 0, 0);
      }
    }
  }
  &__header {
    position: relative;
    z-index: 20;

    .full-width-outer {
      background-color: $backgroundAlt;
      position: relative;
    }
    .full-width-inner {
      padding-top: 100px;
      position: relative;
      z-index: 100;
    }
  }
  &__title {
    color: $black;
    font-family: $boldFont;
    font-size: 60px;
    line-height: 64px;
    margin-bottom: 30px;
    text-align: center;
  }
  &__filters {
    display: flex;
    justify-content: space-between;
  }
  &__filter-group {
    display: flex;
  }
  &__afa-voucher-filter {
    background-image: url('../assets/AfA-logo.svg');
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 16px 16px;
  }
  &__courses {
    padding-bottom: 60px;
    position: relative;
    z-index: 10;

    .full-width-outer {
      position: relative;

      &::before {
        background-color: $backgroundAlt;
        content: '';
        position: absolute;
        left: 0;
        height: 5vw;
        top: 0;
        width: 100vw;
        z-index: 1;
      }
      &::after {
        background: linear-gradient(185deg, $backgroundAlt 50%, $white 50%);
        content: '';
        position: absolute;
        left: 0;
        height: 10vw;
        top: 5vw;
        width: 100vw;
        z-index: 1;
      }
    }
    .full-width-inner {
      padding-top: 30px;
    }
  }
  &__floater-wrapper {
    position: relative;
  }
  &__floater {
    pointer-events: none;
    position: absolute;

    &:nth-child(1) {
      left: -60px;
      top: -10px;
      z-index: 10;
    }
    &:nth-child(2) {
      right: -70px;
      bottom: -70px;
      z-index: 100;
    }
  }
  &__course {
    background-color: $white;
    border: 1px solid $grey;
    border-radius: 4px;
    cursor: pointer;
    margin-bottom: 30px;
    min-height: 140px;
    padding: 30px 40px;
    position: relative;
    transition: background-color $shortTransition ease-in-out;
    z-index: 10;

    &__title {
      font-family: $boldFont;
      font-size: 30px;
      padding-right: $rightPadding;
    }
    &__bootcamp {
      font-family: $demiBoldFont;
      font-size: 18px;
      padding: 0 $rightPadding 15px 0;
    }
    &__short-description {
      font-size: 18px;
      padding-right: $rightPadding;
    }
    &__description {
      display: none;
      font-size: 18px;
      line-height: 22px;
      padding-right: $rightPadding;
    }
    &__extra {
      color: $greyDark;
      display: none;
      font-family: $demiBoldFont;
      font-size: 16px;
      font-style: italic;
      padding-right: $rightPadding;
    }
    &__extra-spacer {
      display: none;
    }
    &__logo {
      height: 80px;
      max-width: 180px;
      position: absolute;
      right: 40px;
      top: 30px;

      img {
        max-height: 100%;
        max-width: 100%;
      }
    }
    &__button {
      display: none;
      bottom: 30px;
      position: absolute;
      right: 40px;
    }
    &__afa-logo {
      background-image: url('../assets/AfA-logo.svg');
      background-repeat: no-repeat;
      background-size: 20px 20px;
      height: 20px;
      left: 10px;
      position: absolute;
      top: 10px;
      width: 20px;
    }
    &:hover {
      background-color: $background;
    }
    &--always:hover {
      background-color: $ctaDark;
    }
    &--always {
      background-color: $cta;
      border-color: $cta;
      color: $white;
    }
    &--active {
      cursor: default;
    }
    &--active &__description,
    &--active &__extra,
    &--active &__extra-spacer,
    &--active &__button, {
      display: block;
    }
    &--always &__description {
      color: $white;
      font-family: $regularFont;
    }
    &--always &__extra {
      color: $white;
    }
    &--always &__afa-logo {
      background-image: url('../assets/AfA-logo-white.svg');
    }
  }

  @include mobile() {
    &--overlay-open {
      height: calc(100vh - 100px);
      overflow: hidden;
    }
    &__intro {
      clip-path: none;
      padding: 40px 10px;
      width: 100%;

      &__heading {
        font-size: 18px;
        line-height: 1.2;
        margin-bottom: 15px;
        padding: 0;
      }
      &__text {
        font-size: 16px;
        line-height: 1.2;
        margin-bottom: 30px;
        padding: 0;
      }
      &__col-heading {
        font-size: 18px;
        line-height: 1.2;
        padding: 0;
      }
      &__col-text {
        font-size: 16px;
        margin-bottom: 15px;
        padding: 0;
      }
      button {
        margin-top: 15px;
      }
    }
    &__form {
      padding: 40px 20px;
      width: 100%;

      &__blocker {
        transform: translate3d(0, 25px, 0);
      }
      &__text {
        margin-bottom: 20px;
        line-height: 1.2;
      }
      &__info {
        line-height: 1.2;
      }
      label {
        margin-bottom: 15px;

        input {
          margin-top: 3px;
        }
      }
    }
    &__header {
      .full-width-inner {
        padding: 40px $horizontalPaddingMobile 0 $horizontalPaddingMobile;
      }
    }
    &__title {
      font-size: 32px;
      line-height: 1.3;
    }
    &__filters {
      display: block;
      padding-right: 15px;
    }
    &__filter-group {
      justify-content: center;
    }
    &__afa-voucher-filter {
      background-size: 10px 10px;
    }
    &__course {
      margin: 0 $horizontalPaddingMobile 30px $horizontalPaddingMobile;
      min-height: 140px;
      padding: $horizontalPaddingMobile;

      &__title {
        font-size: 24px;
        overflow-wrap: break-word;
        padding-right: 80px;
      }
      &__bootcamp {
        overflow-wrap: break-word;
        padding-right: 0;
      }
      &__short-description {
        padding-right: 0;
      }
      &__description {
        padding: 0 0 15px 0;

        p:last-of-type {
          margin-bottom: 0;
        }
      }
      &__logo {
        height: 40px;
        max-width: 60px;
        right: $horizontalPaddingMobile;
        top: 30px;
      }
      &__extra-spacer,
      &__extra {
        padding-bottom: 60px;
      }
      &__afa-logo {
        background-size: 12px 12px;
        height: 12px;
        left: 4px;
        top: 4px;
        width: 12px;
      }
      &__button {
        bottom: $horizontalPaddingMobile;
        right: $horizontalPaddingMobile;
      }
    }
    &__floater {
      animation: none !important;
      display: none;
    }
    &__courses .full-width-outer {
      &::before,
      &::after {
        display: none;
      }
    }
  }
  @include mobileSmall() {
    &__form {
      &__close {
        height: 30px;
        top: 30px;
        width: 30px;
      }
      &__text {
        font-size: 16px;
        margin-bottom: 15px;
      }
    }
  }
}

.slider-block--visible {
  .compass-tool-results__floater {
    &:nth-child(1) {
      animation: floater1 3.5s ease-in-out infinite;
    }
    &:nth-child(2) {
      animation: floaterMobile2Alt 3s ease-in-out infinite;
    }
    @include mobile() {
      &:nth-child(1) {
        animation-name: floaterMobile1;
      }
      &:nth-child(2) {
        animation-name: floaterMobile2;
      }
    }
  }
}
