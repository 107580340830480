// Colours
$white: #fff;
$background: #f8f8f8;
$backgroundAlt: #f6f3ff;
$backgroundAlt2: #f3efff;
$backgroundAlt3: #faf8ff;
$backgroundLight: #fbf9ff;
$backgroundDark: #f1f1f1;
$grey: #c4c4c4;
$greyMiddle: #d9d9d9;
$greyLight: #aaaaaa;
$greyDark: #6a6a6a;
$extraDark: #1e2833;
$black: #000;
$ctaLight: #ede9ff;
$cta: #481bff;
$ctaDark: #3206e2;
$ctaAlt: #6640ff;
$green: #43e1bc;
$red: #f04923;
$pillPink: #C41BFF;
$pillGreen: #34BE95;
$pillDarkGray: #4D4D4D;
$pillYellow: #F4C832;
$pillGreyBlue: #00A3FF;
$pillOrange: #FF881B;
$yellowLight: #FFF5D1;
$blueLight: #D4F0FF;
$orangeLight: #FFE2C7;
$navyLight: #C8DBF8;
$pinkLight: #FFE3F9;
$greenLight: #ECFFE3;

// Dimensions
$maxWidth: 1440px;
$headerHeight: 60px;
$letterSpacing: 2px;
$horizontalPaddingMobile: 17px;

// Durations
$shortTransition: 150ms;
$mediumTransition: 300ms;
$longTransition: 600ms;
$loadingDuration: 900ms;

// Fonts
$regularFont: 'TT Commons Trial Regular';
$mediumFont: 'TT Commons Trial Medium';
$boldFont: 'TT Commons Trial Bold';
$demiBoldFont: 'TT Commons Trial DemiBold';
$ttCommons: 'TT Commons';

// Mixins
@mixin mobileSmall() {
  @media (max-width: 320px) { @content; }
}
@mixin mobile() {
  @media (max-width: 640px) { @content; }
}
@mixin desktop() {
  @media (min-width: 641px) { @content; }
}
@mixin desktopLarge() {
  @media (min-width: $maxWidth) { @content; }
}
