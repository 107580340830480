@import 'variables.scss';

.widget-container {
    position: fixed;
    bottom: 48px;
    right: 30px;
    z-index: 100;
}

.widget {
    width: 80px;
    height: 80px;
    border-radius: 100%;
    background-color: $white;
    -webkit-box-shadow: 0px 0px 24px 0px rgba(0,0,0,0.2); 
    box-shadow: 0px 0px 24px 0px rgba(0,0,0,0.2);
    display: flex;
    justify-content: center;
    align-items: center;
}

.widget-active {
    border: 3px solid $cta;
}

.widget-popup {
  width: 340px;
  height: 247px;
  background-position: center;
  position: absolute;
  bottom: 130px;
  right: 0;
  padding: 2rem;
  display: flex;
  align-items: center;
}

.clipped {
  clip-path: url(#background-mask);
}

.widget-wrapper {
  filter: drop-shadow(0px 0px 24px rgba(0,0,0,0.2));
}
 
.widget-text {
    font-weight: 600;
    font-size: 24px;
    color: $white;
    text-align: center;
}

.widget-popup-button-container {
    display: flex;
    margin-top: 30px;
    justify-content: center;
}