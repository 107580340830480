@import 'variables.scss';

.blog-detail {
  padding-top: 60px;

  .full-width-outer {
    background-color: $backgroundDark;
  }
  .full-width-inner {
    padding: 60px 0 160px 0;
    position: relative;
  }
  &__back-wrapper {
    position: relative;
  }
  &__back {
    background-image: url('~assets/icons/back.svg');
    background-position: center;
    background-repeat: no-repeat;
    background-size: 25px;
    display: block;
    height: 50px;
    opacity: 0.7;
    position: absolute;
    right: -20px;
    top: 0;
    transition: opacity $shortTransition ease-in-out;
    width: 100%;

    &:hover {
      opacity: 1;
    }
  }
  &__title {
    color: $black;
    font-family: $demiBoldFont;
    font-size: 40px;
    line-height: 50px;
    margin: 0 0 20px 0;
  }
  &__date {
    color: $grey;
    font-family: $demiBoldFont;
    font-size: 18px;
    line-height: 22px;
    margin-bottom: 25px;
    position: relative;
  }
  &__share {
    bottom: 0;
    position: absolute;
    right: 0;

    button {
      margin-left: 20px;
    }
  }
  &__content {
    padding-bottom: 100px;
    position: relative;
  }
  &__text {
    color: $black;
    font-size: 18px;
    line-height: 1.4;

    img {
      max-width: 100%;
    }
  }

  @include mobile() {
    padding: 40px $horizontalPaddingMobile 0 $horizontalPaddingMobile;

    .full-width-inner {
      padding: 40px $horizontalPaddingMobile 40px ;
    }
  }
}
