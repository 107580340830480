@import 'variables.scss';

.image-text {
  padding: 120px 0;

  &__title {
    color: $black;
    font-family: $boldFont;
    font-size: 60px;
    margin: 20px 0;
    padding: 0 75px;
  }
  &__text {
    color: $black;
    font-size: 20px;
    line-height: 1.3;
    padding: 0 75px;
  }
  &__image {
    position: relative;

    &__main {
      clip-path: url('#image-mask-small-mirror');
      display: block;
      height: 100%;
      position: relative;
      width: 100%;
      z-index: 200;
    }
    &__background {
      background-color: $cta;
      clip-path: url('#image-mask-small');
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      transform: rotate(3deg);
      width: calc(100% - 40px);
      z-index: 100;

      &::after {
        background-color: $white;
        clip-path: url('#image-mask-small');
        content: '';
        height: calc(100% - 2px);
        left: 1px;
        position: absolute;
        top: 1px;
        width: calc(100% - 2px);
        z-index: 150;
      }
    }
  }

  @include mobile() {
    padding: 90px $horizontalPaddingMobile;

    &__title {
      margin-top: 50px;
      font-size: 50px;
      padding: 0;
    }
    &__text {
      padding: 0;
    }
    &__image-wrapper {
      order: 0;
    }
    &__image {
      padding: 0;

      &__main {
        width: 100%;
      }
    }
  }
}
