@import 'variables.scss';

.checkbox {
  .label {
    color: $white;
    cursor: pointer;
    display: flex;
    font-size: 20px;
    line-height: 1.2;
    margin-bottom: 20px;

    a {
      color: $white;
      position: relative;
      text-decoration: none;

      &::after {
        background-color: rgba($white, 0.6);
        bottom: 0;
        content: '';
        display: block;
        height: 1px;
        left: 0;
        position: absolute;
        width: 100%;
      }
      &:hover {
        color: $ctaLight;
      }
    }
  }
  .input {
    appearance: none;
    border-radius: 4px;
    border: 1px solid $grey;
    height: 22px;
    margin: 0 12px 0 0;
    max-width: 22px;
    min-width: 22px;
    transition: background-color $shortTransition ease-in-out;
    width: 22px;

    &:hover {
      background-color: rgba($white, 0.1);
    }
    &:checked {
      background-color: rgba($white, 0.1);
      background-image: url('~assets/icons/check.svg');
      background-position: center;
      background-repeat: no-repeat;
    }
    &[disabled] {
      cursor: not-allowed;
      opacity: 0.5;
    }
  }

  @include mobile() {
    .label {
      font-size: 16px;
    }
  }
}
